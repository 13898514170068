import { EmptyState, LegacyCard } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { ISellingPlanGroupCreate } from "@smartrr/shared/entities/SellingPlanGroup";
import { cloneDeep, omit } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import { AdminSellingPlanGroupRouteWithData } from "./AdminSellingPlanGroupRouteWithData";
import { Spinner } from "../../components/elements/Spinner";
import { emptySmartrrSellingPlanGroup } from "../constants";
import { SetupStoreAccess } from "../../AdminSetupRoute/libs/store";

export function AdminSellingPlanGroupRoute({ planId }: { planId: string }): JSX.Element {
  const { addToast } = useToast();
  const isCreatingNew = planId === "create";

  const sellingPlanGroup = SellingPlanGroupStoreAccess.useSelectedGroup();
  const sellingPlanGroups = SellingPlanGroupStoreAccess.useGroups();
  const isGroupLoading = SellingPlanGroupStoreAccess.useIsGroupLoading();
  const groupActions = SellingPlanGroupStoreAccess.useActions();

  const { initialize: initializeSetup } = SetupStoreAccess.useActions();

  const initialSellingPlanGroupRef = useRef<ISellingPlanGroupCreate | null>(
    isCreatingNew ? emptySmartrrSellingPlanGroup : sellingPlanGroup
  );

  const { isLoading: isPurchasablesLoading } = useSmartrrVendorSelector(state => state.purchasables);

  const [isLoading, setIsLoading] = useState(isCreatingNew ? false : isGroupLoading);

  const getSellingPlanGroup = async (id: string) => {
    const sellingPlanGroupRes = await groupActions.fetchSellingPlanGroup(id);
    if (sellingPlanGroupRes.result === "success") {
      const sellingPlanGroup = sellingPlanGroupRes.data;
      initialSellingPlanGroupRef.current = cloneDeep(sellingPlanGroup);
    }
  };

  useEffect(() => {
    if (planId && !isCreatingNew) {
      void fetchGroup(planId);
    }
  }, [isCreatingNew, planId]);

  // Necessary to fetch setup as it's used in the component
  useEffect(() => {
    void initializeSetup(true);
  }, []);

  const fetchGroup = async (id: string) => {
    setIsLoading(true);
    await getSellingPlanGroup(id);
    setIsLoading(false);
  };

  const onSave = useCallback(
    (newGroup: ISellingPlanGroupCreate) => {
      initialSellingPlanGroupRef.current = cloneDeep(newGroup);
      initialSellingPlanGroupRef.current.sellingPlans = newGroup.sellingPlans.map(p => cloneDeep(p));
    },
    [initialSellingPlanGroupRef.current]
  );

  const onDiscard = useCallback(() => {
    if (isCreatingNew) {
      void navigateWithShopInQuery(`${adminRoutePrefix}/configure/plans`);
    }
  }, [isCreatingNew]);

  const onDelete = async (id: string): Promise<boolean> => {
    addToast("Deleting program...");
    setIsLoading(true);

    const deletedGroupRes = await groupActions.deleteSellingPlanGroup(id);

    if (deletedGroupRes.result !== "success") {
      addToast("Couldn't remove the subscription program");
      return false;
    }

    addToast("Program deleted");
    setIsLoading(false);
    return true;
  };

  if ((isLoading || isPurchasablesLoading) && !isCreatingNew) {
    return <Spinner />;
  }

  if (!isLoading && !initialSellingPlanGroupRef.current && !isCreatingNew && !isPurchasablesLoading) {
    return (
      <LegacyCard>
        <EmptyState
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
          action={{
            content: "Manage plans",
            onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/configure/plans`),
          }}
        >
          <p>Subscription program not found</p>
        </EmptyState>
      </LegacyCard>
    );
  }

  return (
    <AdminSellingPlanGroupRouteWithData
      sellingPlanGroup={omit(initialSellingPlanGroupRef.current, [
        "sellingPlans",
        "productIds",
        "productVariantIds",
      ])}
      doSellingPlanGroupsExist={sellingPlanGroups.length > 0}
      variantIds={initialSellingPlanGroupRef.current?.productVariantIds ?? []}
      productIds={initialSellingPlanGroupRef.current?.productIds ?? []}
      sellingPlans={initialSellingPlanGroupRef.current?.sellingPlans ?? []}
      onSave={onSave}
      onDiscard={onDiscard}
      onDelete={onDelete}
    />
  );
}
